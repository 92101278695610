import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/data/src/pages/.umi-production/LocaleWrapper.jsx';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    title: '用户布局',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/data/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/data/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/data/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/registerW-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/data/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/offer',
    title: 'Offer邮件布局',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__OfferLayout" */ '../../layouts/OfferLayout'),
          LoadingComponent: require('/data/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/OfferLayout').default,
    routes: [
      {
        path: '/offer',
        redirect: '/offer/invitation',
        exact: true,
      },
      {
        path: '/offer/invitation/:id',
        name: 'invitation',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/data/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Offer" */ '../User/Offer'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Offer').default,
        exact: true,
      },
      {
        path: '/offer/invitation/',
        name: 'invitation',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/data/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Offer" */ '../User/Offer'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Offer').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/enterGuide',
    title: '欢迎页',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__EntryGuideLayout" */ '../../layouts/EntryGuideLayout'),
          LoadingComponent: require('/data/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/EntryGuideLayout').default,
    routes: [
      {
        path: '/enterGuide',
        redirect: '/enterGuide/welcome',
        exact: true,
      },
      {
        title: '欢迎加入',
        path: '/enterGuide/welcome',
        name: 'welcome',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__Welcome" */ '../WorkBench/Welcome'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/Welcome').default,
        exact: true,
      },
      {
        path: '/enterGuide/guide',
        title: '入职指引',
        name: 'guide',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__StaffJoin" */ '../WorkBench/StaffJoin'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/StaffJoin').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    title: '全局布局',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/data/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    routes: [
      {
        path: '/',
        title: '重定向桌面',
        redirect: '/dashboard',
        exact: true,
      },
      {
        path: '/dashboard',
        title: '系统桌面',
        name: 'dashboard',
        icon: 'dashboard',
        routes: [
          {
            path: '/dashboard',
            title: '系统桌面',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Dashboard__models__activities.js' */ '/data/src/pages/Dashboard/models/activities.js').then(
                      m => {
                        return { namespace: 'activities', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__chart.js' */ '/data/src/pages/Dashboard/models/chart.js').then(
                      m => {
                        return { namespace: 'chart', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Dashboard__models__monitor.js' */ '/data/src/pages/Dashboard/models/monitor.js').then(
                      m => {
                        return { namespace: 'monitor', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard__SystemDesktop" */ '../Dashboard/SystemDesktop'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard/SystemDesktop').default,
            exact: true,
          },
          {
            path: '/dashboard/needInvite',
            title: '简历任务',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__PushResumeDemand" */ '../Notification/Tasks/PushResumeDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/PushResumeDemand').default,
            exact: true,
          },
          {
            path: '/dashboard/ResumeInvite',
            title: '简历任务详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__HaveToPushDemand" */ '../Notification/Tasks/HaveToPushDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/HaveToPushDemand').default,
            exact: true,
          },
        ],
      },
      {
        path: '/department',
        title: '通讯录',
        name: 'department',
        icon: 'team',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__Department" */ '../WorkBench/Department'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/Department').default,
        exact: true,
      },
      {
        path: '/department/turnover',
        title: '离职详情',
        name: 'deptTurnover',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__Department__DeptLeaveDetail" */ '../WorkBench/Department/DeptLeaveDetail'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/Department/DeptLeaveDetail').default,
        exact: true,
      },
      {
        path: '/projectTeam',
        title: '我的项目组',
        name: 'projectTeam',
        icon: 'team',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__Department__ProjectTeam" */ '../WorkBench/Department/ProjectTeam'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/Department/ProjectTeam').default,
        exact: true,
      },
      {
        path: '/approval',
        title: '办公审批',
        icon: 'audit',
        name: 'approval',
        routes: [
          {
            path: '/approval/my-approval',
            title: '我的审批',
            name: 'my-approval',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__MyApproval__MyApproval" */ '../Approval/MyApproval/MyApproval.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/MyApproval/MyApproval.jsx').default,
            exact: true,
          },
          {
            path: '/approval',
            redirect: '/approval/initiate',
            exact: true,
          },
          {
            path: '/approval/initiate',
            title: '发起审批',
            name: 'initiate',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateApproval" */ '../Approval/InitiateApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateApproval').default,
            exact: true,
          },
          {
            path: '/approval/quit',
            title: '发起离职',
            name: 'quit',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateLeaveApproval" */ '../Approval/InitiateForm/InitiateLeaveApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateLeaveApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/restart/:leaveProcessId',
            title: '重启离职',
            name: 'restartLeave',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateLeaveApproval" */ '../Approval/InitiateForm/InitiateLeaveApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateLeaveApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/quitapproval/:leaveProcessId',
            name: 'quitapproval',
            hideInMenu: true,
            title: '离职审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__LeaveApprovalDetail" */ '../Approval/ApprovalDetails/LeaveApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/LeaveApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/approval/autoleave',
            name: 'autoleave',
            hideInMenu: true,
            title: '员工自离',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateAutoLeaveApproval" */ '../Approval/InitiateForm/InitiateAutoLeaveApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateAutoLeaveApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/autoleaveapproval/:procLeaveId',
            name: 'autoleaveapproval',
            hideInMenu: true,
            title: '审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__AutoLeaveApprovalDetail" */ '../Approval/ApprovalDetails/AutoLeaveApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/AutoLeaveApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/approval/restartautoleave/:procLeaveId',
            name: 'restartautoleave',
            hideInMenu: true,
            title: '重启自离',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateAutoLeaveApproval" */ '../Approval/InitiateForm/InitiateAutoLeaveApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateAutoLeaveApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/transfer',
            name: 'transfer',
            title: '调岗',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Template__Template" */ '../Template/Template'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Template').default,
            exact: true,
          },
          {
            path: '/approval/rest',
            name: 'rest',
            hideInMenu: true,
            title: '请假审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Template__Template" */ '../Template/Template'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Template/Template').default,
            exact: true,
          },
          {
            path: '/approval/renewal',
            name: 'renewal',
            hideInMenu: true,
            title: '续签审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetail__InitiateRenewalApproval" */ '../Approval/ApprovalDetail/InitiateRenewalApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetail/InitiateRenewalApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/recruit',
            name: 'recruit',
            hideInMenu: true,
            title: '本部人员招聘审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetail__InitiateRecruitApproval" */ '../Approval/ApprovalDetail/InitiateRecruitApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetail/InitiateRecruitApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/recruitmentdemand/:id',
            name: 'recruitmentdemand',
            hideInMenu: true,
            title: '本部人员招聘审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__RecruitmentDetail__RecruitmentDetail" */ '../Approval/RecruitmentDetail/RecruitmentDetail.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/RecruitmentDetail/RecruitmentDetail.jsx')
                  .default,
            exact: true,
          },
          {
            path: '/approval/hqoffer',
            name: 'hqoffer',
            hideInMenu: true,
            title: '本部offer审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__HqOffer__HqOffer" */ '../Approval/HqOffer/HqOffer.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/HqOffer/HqOffer.jsx').default,
            exact: true,
          },
          {
            path: '/approval/hqofferdetail/:id',
            name: 'hqofferdetail',
            hideInMenu: true,
            title: '本部offer审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__HqOfferDetail__HqOfferDetail" */ '../Approval/HqOfferDetail/HqOfferDetail.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/HqOfferDetail/HqOfferDetail.jsx').default,
            exact: true,
          },
          {
            path: '/approval/offer',
            name: 'offer',
            title: 'offer审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateOfferApproval" */ '../Approval/InitiateForm/InitiateOfferApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateOfferApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/offerapproval/:offerProcessId',
            name: 'offerapproval',
            hideInMenu: true,
            title: 'offer审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__OfferApprovalDetail" */ '../Approval/ApprovalDetails/OfferApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/OfferApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/approval/restartoffer/:offerProcessId',
            name: 'restartoffer',
            hideInMenu: true,
            title: 'offer重启',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateOfferApproval" */ '../Approval/InitiateForm/InitiateOfferApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateOfferApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/proof',
            name: 'proof',
            hideInMenu: true,
            title: '证明开具',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateProofApproval" */ '../Approval/InitiateForm/InitiateProofApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateProofApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/break',
            name: 'break',
            hideInMenu: true,
            title: '发起调休',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateHolidayApproval" */ '../Approval/InitiateForm/InitiateHolidayApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateHolidayApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/projectholiday',
            name: 'projectholiday',
            hideInMenu: true,
            title: '请假审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateHolidayApproval" */ '../Approval/InitiateForm/InitiateHolidayApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateHolidayApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/projectholidayapproval/:holidayProcessId',
            name: 'projectholidayapproval',
            hideInMenu: true,
            title: '重启请假审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__HolidayApprovalDetail" */ '../Approval/ApprovalDetails/HolidayApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/HolidayApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/approval/restartprojectholiday/:holidayProcessId',
            name: 'restartprojectholiday',
            hideInMenu: true,
            title: '请假审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateHolidayApproval" */ '../Approval/InitiateForm/InitiateHolidayApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateHolidayApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/goout',
            name: 'goout',
            title: '发起外出出差审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateGoOutApproval" */ '../Approval/InitiateForm/InitiateGoOutApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateGoOutApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/gooutapproval/:holidayProcessId',
            name: 'gooutapproval',
            hideInMenu: true,
            title: '外出出差审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__GoOutDetail" */ '../Approval/ApprovalDetails/GoOutDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/GoOutDetail').default,
            exact: true,
          },
          {
            path: '/approval/restartgoout/:holidayProcessId',
            name: 'restartgoout',
            title: '重启外出出差审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateGoOutApproval" */ '../Approval/InitiateForm/InitiateGoOutApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateGoOutApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/overTime',
            name: 'overTime',
            title: '发起加班审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateOverTimeApproval" */ '../Approval/InitiateForm/InitiateOverTimeApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateOverTimeApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/overtimeapproval/:procDetailId',
            name: 'overtimeapproval',
            title: '加班审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__OverTimeDetail" */ '../Approval/ApprovalDetails/OverTimeDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/OverTimeDetail').default,
            exact: true,
          },
          {
            path: '/approval/restartovertime/:procDetailId',
            name: 'restartoverTime',
            title: '重启加班审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateOverTimeApproval" */ '../Approval/InitiateForm/InitiateOverTimeApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateOverTimeApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/patch',
            name: 'patch',
            hideInMenu: true,
            title: '发起补卡审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiatePatchApproval" */ '../Approval/InitiateForm/InitiatePatchApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiatePatchApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/patchapproval/:procDetailId',
            name: 'patchapproval',
            title: '补卡审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__PatchApprovalDetail" */ '../Approval/ApprovalDetails/PatchApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/PatchApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/approval/restartpatch/:procDetailId',
            name: 'restartpatch',
            title: '重启补卡审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiatePatchApproval" */ '../Approval/InitiateForm/InitiatePatchApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiatePatchApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/salarychange',
            name: 'salarychange',
            hideInMenu: true,
            title: '发起员工调薪',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateSalaryApproval" */ '../Approval/InitiateForm/InitiateSalaryApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateSalaryApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/salarychangeapproval/:salaryProcessId',
            name: 'salarychangeapproval',
            hideInMenu: true,
            title: '调薪审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__SalaryApproval" */ '../Approval/ApprovalDetails/SalaryApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/SalaryApproval').default,
            exact: true,
          },
          {
            path: '/approval/restartsalarychange/:salaryProcessId',
            name: 'restartsalarychange',
            hideInMenu: true,
            title: '重启员工调薪',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateSalaryApproval" */ '../Approval/InitiateForm/InitiateSalaryApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateSalaryApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/reimbursement',
            name: 'reimbursement',
            title: '报销费用',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateReimbursementApproval" */ '../Approval/InitiateForm/InitiateReimbursementApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateReimbursementApproval')
                  .default,
            exact: true,
          },
          {
            path: '/approval/releaseterm',
            name: 'releaseterm',
            title: '释放转项审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateReleaseterm" */ '../Approval/InitiateForm/InitiateReleaseterm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateReleaseterm').default,
            exact: true,
          },
          {
            path: '/approval/releaseApproval/:procDetailId',
            name: 'releaseApproval',
            title: '释放转项审批详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__ReleaseApproval" */ '../Approval/ApprovalDetails/ReleaseApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/ReleaseApproval').default,
            exact: true,
          },
          {
            path: '/approval/restartReleaseterm/:procDetailId',
            name: 'restartReleaseterm',
            title: '重启释放转项审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateReleaseterm" */ '../Approval/InitiateForm/InitiateReleaseterm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateReleaseterm').default,
            exact: true,
          },
        ],
      },
      {
        path: '/report',
        title: '工作报告',
        name: 'report',
        icon: 'coffee',
        routes: [
          {
            path: '/report',
            redirect: '/report/list',
            exact: true,
          },
          {
            path: '/report/list',
            title: '报告列表',
            name: 'list',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__WorkReport__WorkReportList" */ '../WorkBench/WorkReport/WorkReportList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/WorkReport/WorkReportList').default,
            exact: true,
          },
          {
            path: '/report/detail/:id',
            title: '工作报告详情',
            name: 'reportDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__WorkReport__WorkReportDetail" */ '../WorkBench/WorkReport/WorkReportDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/WorkReport/WorkReportDetail').default,
            exact: true,
          },
          {
            path: '/report/person/:id',
            title: '个人工作报告统计',
            name: 'personDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__WorkReport__PersonReportHistory" */ '../WorkBench/WorkReport/PersonReportHistory'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/WorkReport/PersonReportHistory').default,
            exact: true,
          },
        ],
      },
      {
        path: '/file',
        title: '文件柜',
        hideInMenu: true,
        name: 'file',
        icon: 'file',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Document__StaffDocument" */ '../Document/StaffDocument'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../Document/StaffDocument').default,
        exact: true,
      },
      {
        path: '/rules',
        title: '规章制度',
        name: 'rules',
        icon: 'hdd',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Administrative__Regulations__RegulationStaff" */ '../Administrative/Regulations/RegulationStaff'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../Administrative/Regulations/RegulationStaff').default,
        exact: true,
      },
      {
        path: '/attendance',
        title: '考勤数据',
        icon: 'calendar',
        name: 'attendance',
        routes: [
          {
            path: '/attendance/record',
            title: '当月考勤记录',
            name: 'record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__CheckWork__AttendanceRecord" */ '../WorkBench/CheckWork/AttendanceRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/CheckWork/AttendanceRecord').default,
            exact: true,
          },
          {
            path: '/attendance/hisrecord',
            title: '历史考勤记录',
            name: 'hisrecord',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__CheckWork__HisAttendanceRecord" */ '../WorkBench/CheckWork/HisAttendanceRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/CheckWork/HisAttendanceRecord').default,
            exact: true,
          },
          {
            path: '/attendance/declaration',
            title: '考勤申报',
            name: 'declaration',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__Attendance__Declaration" */ '../WorkBench/Attendance/Declaration'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/Attendance/Declaration').default,
            exact: true,
          },
          {
            path: '/attendance/specialDeclaration',
            title: '特殊考勤申报',
            hideInMenu: true,
            name: 'declaration',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__Attendance__SpecialDeclaration" */ '../WorkBench/Attendance/SpecialDeclaration'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/Attendance/SpecialDeclaration').default,
            exact: true,
          },
          {
            path: '/attendance/hisdeclaration',
            title: '历史考勤申报',
            name: 'hisdeclaration',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__Attendance__HisDeclaration" */ '../WorkBench/Attendance/HisDeclaration'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/Attendance/HisDeclaration').default,
            exact: true,
          },
          {
            path: '/attendance/holiday',
            title: '假期统计',
            name: 'holiday',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__HolidayStatistics" */ '../WorkBench/HolidayStatistics'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/HolidayStatistics').default,
            exact: true,
          },
        ],
      },
      {
        path: '/personalInfo',
        title: '岗位信息',
        name: 'personalInfo',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__PersonalAndPostInfo" */ '../WorkBench/PersonalAndPostInfo'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/PersonalAndPostInfo').default,
        exact: true,
      },
      {
        path: '/onboardingInfo',
        title: '入职信息表',
        name: 'onboardingInfo',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__MessageCard" */ '../WorkBench/MessageCard'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/MessageCard').default,
        exact: true,
      },
      {
        path: '/payroll',
        title: '工资单',
        name: 'payroll',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__StaffPersonPayroll" */ '../WorkBench/StaffPersonPayroll'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/StaffPersonPayroll').default,
        exact: true,
      },
      {
        path: '/equipment',
        title: '我的设备',
        name: 'equipment',
        hideInMenu: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkBench__MyDevices" */ '../WorkBench/MyDevices'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkBench/MyDevices').default,
        exact: true,
      },
      {
        name: 'account',
        icon: 'user',
        title: '个人设置',
        path: '/account',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Account__Center__Articles" */ '../Account/Center/Articles'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../Account/Center/Articles').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/feedBack',
        name: 'feedBack',
        title: '吐个槽',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Notification__FeedBack" */ '../Notification/FeedBack'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../Notification/FeedBack').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/feedBackHistory',
        name: 'feedBackHistory',
        title: '历史吐槽',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Notification__FeedBackHistory" */ '../Notification/FeedBackHistory'),
              LoadingComponent: require('/data/src/components/PageLoading/index')
                .default,
            })
          : require('../Notification/FeedBackHistory').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/personnel',
        icon: 'contacts',
        title: '人事管理',
        name: 'personnel',
        routes: [
          {
            path: '/personnel/staff',
            title: '人事花名册',
            name: 'staff',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Employee" */ '../Personnel/Employee'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Employee').default,
            exact: true,
          },
          {
            path: '/personnel/staff/importrecord',
            title: '导入记录',
            name: 'importrecord',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__importRecord" */ '../Personnel/importRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/importRecord').default,
            exact: true,
          },
          {
            path: '/personnel/edit/:id',
            title: '编辑员工信息',
            name: 'editStaff',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__EditEmployee" */ '../Personnel/EditEmployee'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/EditEmployee').default,
            exact: true,
          },
          {
            path: '/personnel/detail',
            title: '员工信息详情',
            name: 'detail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__EmployeeDetail" */ '../Personnel/EmployeeDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/EmployeeDetail').default,
            exact: true,
          },
          {
            path: '/personnel/onboard',
            title: '入职管理',
            name: 'onboard',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__OnboardManage" */ '../Personnel/OnboardManage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/OnboardManage').default,
            exact: true,
          },
          {
            path: '/personnel/onboardInfo/:id',
            name: 'onboardInfo',
            title: '入职资料详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__OnboardStaffInfo" */ '../Personnel/OnboardStaffInfo'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/OnboardStaffInfo').default,
            exact: true,
          },
          {
            path: '/personnel/onboard/audit/:id',
            title: '员工信息审核',
            name: 'audit',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__EmployeeAudit" */ '../Personnel/EmployeeAudit'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/EmployeeAudit').default,
            exact: true,
          },
          {
            path: '/personnel/onboardingInfoprint',
            title: '打印员工入职信息表',
            name: 'onboardingInfoprint',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__PrintMessageCard" */ '../Personnel/PrintMessageCard'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/PrintMessageCard').default,
            exact: true,
          },
          {
            path: '/personnel/regular',
            title: '转正管理',
            name: 'regular',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__RegularManage" */ '../Personnel/RegularManage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/RegularManage').default,
            exact: true,
          },
          {
            path: '/personnel/regularDetail/:id',
            title: '转正员工详情',
            name: 'regularDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__positiveDetail" */ '../Approval/ApprovalDetails/positiveDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/positiveDetail').default,
            exact: true,
          },
          {
            path: '/personnel/staff/regularPrint',
            title: '打印转正通知',
            name: 'regularPrint',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ProofPrintPreview" */ '../Personnel/ProofPrintPreview'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ProofPrintPreview').default,
            exact: true,
          },
          {
            path: '/personnel/renewal',
            title: '续签管理',
            name: 'renewal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__RenewalManagement" */ '../Personnel/RenewalManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/RenewalManagement').default,
            exact: true,
          },
          {
            path: '/personnel/renewalDetail/:id',
            name: 'renewalDetail',
            title: '续签员工详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__RenewalDetail" */ '../Personnel/RenewalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/RenewalDetail').default,
            exact: true,
          },
          {
            path: '/personnel/turnover',
            name: 'turnover',
            title: '离职管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__TurnoverManagement" */ '../Personnel/TurnoverManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/TurnoverManagement').default,
            exact: true,
          },
          {
            path: '/personnel/medical',
            title: '体检单',
            name: 'medical',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__MedicalChecklist" */ '../Personnel/MedicalChecklist'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/MedicalChecklist').default,
            exact: true,
          },
          {
            path: '/personnel/proof',
            title: '证明开具',
            name: 'proof',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ProofOpener" */ '../Personnel/ProofOpener'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ProofOpener').default,
            exact: true,
          },
          {
            path: '/personnel/print',
            title: '证明打印预览',
            name: 'print',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ProofPrintPreview" */ '../Personnel/ProofPrintPreview'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ProofPrintPreview').default,
            exact: true,
          },
          {
            path: '/personnel/contractManage',
            title: '合同管理',
            name: 'contractManage',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ContractManage" */ '../Personnel/ContractManage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ContractManage').default,
            exact: true,
          },
          {
            path: '/personnel/contractManageDetail/:id',
            title: '合同管理详情',
            name: 'contractManageDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ContractManageDetail" */ '../Personnel/ContractManageDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ContractManageDetail').default,
            exact: true,
          },
          {
            path: '/personnel/release',
            title: '释放管理',
            name: 'release',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ReleaseManagement" */ '../Personnel/ReleaseManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ReleaseManagement').default,
            exact: true,
          },
          {
            path: '/personnel/survey',
            title: '离职调查',
            name: 'survey',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__TurnoverSurvey" */ '../Personnel/TurnoverSurvey'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/TurnoverSurvey').default,
            exact: true,
          },
          {
            path: '/personnel/surveyDetail',
            title: '离职调查详情',
            name: 'surveyDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__TurnoverSurveyDetail" */ '../Personnel/TurnoverSurveyDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/TurnoverSurveyDetail').default,
            exact: true,
          },
          {
            path: '/personnel/setting',
            title: '人事设置',
            name: 'setting',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Setting" */ '../Administrative/Setting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Setting').default,
            exact: true,
          },
          {
            path: '/personnel/changesList',
            title: '异动记录',
            name: 'changesList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Changes__ChangesList" */ '../Personnel/Changes/ChangesList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Changes/ChangesList').default,
            exact: true,
          },
          {
            path: '/personnel/changesInfo',
            title: '异动详情',
            hideInMenu: true,
            name: 'changesInfo',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Changes__ChangesInfo" */ '../Personnel/Changes/ChangesInfo'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Changes/ChangesInfo').default,
            exact: true,
          },
          {
            path: '/personnel/historyChangesList',
            title: '历史异动记录',
            hideInMenu: true,
            name: 'historyChangesList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Changes__HistoryChangesList" */ '../Personnel/Changes/HistoryChangesList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Changes/HistoryChangesList').default,
            exact: true,
          },
          {
            path: '/personnel/salary',
            title: '薪资档案',
            hideInMenu: true,
            name: 'salary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__SalaryRecords__index" */ '../Personnel/SalaryRecords/index.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/SalaryRecords/index.jsx').default,
            exact: true,
          },
          {
            path: '/personnel/move',
            title: '人事调动',
            hideInMenu: true,
            name: 'move',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__StaffMovement__index" */ '../Personnel/StaffMovement/index.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/StaffMovement/index.jsx').default,
            exact: true,
          },
        ],
      },
      {
        path: '/attendanceManagement',
        name: 'attendanceManagement',
        title: '考勤管理',
        icon: 'reconciliation',
        routes: [
          {
            path: '/attendanceManagement',
            title: '重定向',
            redirect: '/attendanceManagement/monthAttdance',
            exact: true,
          },
          {
            path: '/attendanceManagement/timeSheet',
            name: 'timeSheet',
            title: '客户工时',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__TimeSheet" */ '../Attendance/TimeSheet'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/TimeSheet').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/monthAttdance',
            name: 'monthAttdance',
            title: '当月考勤',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__MonthAttdance" */ '../Attendance/MonthAttdance'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/MonthAttdance').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/historicalAttendance',
            name: 'historicalAttendance',
            title: '历史考勤',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HistoricalAttendance" */ '../Attendance/HistoricalAttendance'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HistoricalAttendance').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/setting',
            name: 'setting',
            title: '考勤设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__AttendanceSetting" */ '../Attendance/AttendanceSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/AttendanceSetting').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/attendanceOperation',
            name: 'attendanceOperation',
            title: '考勤操作记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__MonthAttdance__AttendanceOperation" */ '../Attendance/MonthAttdance/AttendanceOperation'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/MonthAttdance/AttendanceOperation')
                  .default,
            exact: true,
          },
          {
            path: '/attendanceManagement/schemes',
            name: 'schemes',
            hideInMenu: true,
            title: '考勤方案',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__Forms__AttenSchemes" */ '../Attendance/Forms/AttenSchemes'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/Forms/AttenSchemes').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/schemes/:id',
            name: 'edit',
            hideInMenu: true,
            title: '编辑考勤方案',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__Forms__AttenSchemes" */ '../Attendance/Forms/AttenSchemes'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/Forms/AttenSchemes').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/attendanceFree',
            name: 'attendanceFree',
            hideInMenu: true,
            title: '编辑免卡人员',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__Forms__AttendanceFree" */ '../Attendance/Forms/AttendanceFree'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/Forms/AttendanceFree').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/annualLeave',
            name: 'annualLeave',
            title: '年假统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__AnnualLeaveStatistics" */ '../Attendance/AnnualLeaveStatistics'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/AnnualLeaveStatistics').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/overtimeStatis',
            name: 'overtimeStatis',
            title: '加班调休记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__OvertimeStatistics" */ '../Attendance/OvertimeStatistics'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/OvertimeStatistics').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/departmentAttendance/:id',
            name: 'departmentAttendance',
            hideInMenu: true,
            title: '本部员工考勤明细',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__DecclartionDetailPart" */ '../Attendance/DecclartionDetailPart'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/DecclartionDetailPart').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/declarationList/:id',
            name: 'declarationList',
            hideInMenu: true,
            title: '项目员工考勤申报',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__DeclarationDetail" */ '../Attendance/DeclarationDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/DeclarationDetail').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/editholiday',
            name: 'editholiday',
            hideInMenu: true,
            title: '假期配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__PersonalNursingFuneralLeave" */ '../Attendance/EditVacation/PersonalNursingFuneralLeave'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/PersonalNursingFuneralLeave')
                  .default,
            exact: true,
          },
          {
            path: '/attendanceManagement/editleave',
            name: 'editleave',
            hideInMenu: true,
            title: '假期配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__MarriageMaternityrelatedLeave" */ '../Attendance/EditVacation/MarriageMaternityrelatedLeave'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/MarriageMaternityrelatedLeave')
                  .default,
            exact: true,
          },
          {
            path: '/attendanceManagement/editsickleave',
            name: 'editsickleave',
            hideInMenu: true,
            title: '假期配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__SickLeave" */ '../Attendance/EditVacation/SickLeave'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/SickLeave').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/editannualleave',
            name: 'editannualleave',
            hideInMenu: true,
            title: '假期配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__AnnualLeave" */ '../Attendance/EditVacation/AnnualLeave'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/AnnualLeave').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/overtime',
            name: 'overtime',
            title: '添加加班调休规则',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__Overtime" */ '../Attendance/EditVacation/Overtime'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/Overtime').default,
            exact: true,
          },
          {
            path: '/attendanceManagement/overtime/:id',
            name: 'editOvertime',
            title: '编辑加班调休规则',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__EditVacation__Overtime" */ '../Attendance/EditVacation/Overtime'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/EditVacation/Overtime').default,
            exact: true,
          },
        ],
      },
      {
        path: '/train',
        title: '培训管理',
        name: 'train',
        icon: 'play-square',
        routes: [
          {
            path: '/train',
            title: '重定向',
            redirect: '/train/record',
            exact: true,
          },
          {
            path: '/train/record',
            title: '培训档案',
            name: 'record',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__TrainingRecord" */ '../Personnel/Training/TrainingRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/TrainingRecord').default,
            exact: true,
          },
          {
            path: '/train/material',
            title: '培训资料',
            name: 'material',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__TrainingMaterial" */ '../Personnel/Training/TrainingMaterial'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/TrainingMaterial').default,
            exact: true,
          },
          {
            path: '/train/add',
            title: '添加培训资料',
            name: 'add',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__TrainingForm" */ '../Personnel/Training/TrainingForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/TrainingForm').default,
            exact: true,
          },
          {
            path: '/train/edit/:id',
            title: '编辑培训资料',
            name: 'edit',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__TrainingForm" */ '../Personnel/Training/TrainingForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/TrainingForm').default,
            exact: true,
          },
          {
            path: '/train/question',
            title: '题库管理',
            name: 'question',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__TrainingQuestion" */ '../Personnel/Training/TrainingQuestion'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/TrainingQuestion').default,
            exact: true,
          },
          {
            path: '/train/questionDetail/:id',
            title: '题库编辑',
            name: 'questionDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__Training__QuestionForm" */ '../Personnel/Training/QuestionForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/Training/QuestionForm').default,
            exact: true,
          },
        ],
      },
      {
        path: '/socialInsurance',
        title: '五险一金',
        name: 'socialInsurance',
        icon: 'pay-circle',
        routes: [
          {
            path: '/socialInsurance',
            title: '重定向',
            redirect: '/socialInsurance/increase',
            exact: true,
          },
          {
            path: '/socialInsurance/increase',
            name: 'increase',
            title: '本月增减表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__SocialInsurance__IncreaseAndDecrease" */ '../Personnel/SocialInsurance/IncreaseAndDecrease'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/SocialInsurance/IncreaseAndDecrease')
                  .default,
            exact: true,
          },
          {
            path: '/socialInsurance/comprehensive',
            name: 'comprehensive',
            title: '本月综合表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__SocialInsurance__ComprehensiveTable" */ '../Personnel/SocialInsurance/ComprehensiveTable'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/SocialInsurance/ComprehensiveTable')
                  .default,
            exact: true,
          },
          {
            path: '/socialInsurance/history',
            name: 'history',
            title: '历史数据',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__SocialInsurance__HistoryList" */ '../Personnel/SocialInsurance/HistoryList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/SocialInsurance/HistoryList').default,
            exact: true,
          },
          {
            path: '/socialInsurance/socialSetting',
            name: 'socialSetting',
            title: '社保配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__SocialSetting" */ '../Administrative/SocialSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/SocialSetting').default,
            exact: true,
          },
        ],
      },
      {
        path: '/administrative',
        name: 'administrative',
        title: '行政',
        icon: 'apartment',
        routes: [
          {
            path: '/administrative/approval-setting',
            name: 'approval-setting',
            title: '审批设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ApprovalSetting" */ '../ApprovalSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../ApprovalSetting').default,
            exact: true,
          },
          {
            path: '/administrative/flowSetting/:approvalProcessKey',
            name: 'flowSetting',
            title: '简历任务详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ApprovalSetting__ApprovalSettingFlow" */ '../ApprovalSetting/ApprovalSettingFlow'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../ApprovalSetting/ApprovalSettingFlow').default,
            exact: true,
          },
          {
            path: '/administrative/flowSettingSubpage/:processConditionsId',
            name: 'flowSettingSubpage',
            title: '条件流程配置',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__ApprovalSetting__FlowSettingSubpage" */ '../ApprovalSetting/FlowSettingSubpage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../ApprovalSetting/FlowSettingSubpage').default,
            exact: true,
          },
          {
            path: '/administrative/approval-log',
            name: 'approval-log',
            title: '审批记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalLog__ApprovalLog" */ '../Administrative/ApprovalLog/ApprovalLog.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalLog/ApprovalLog.jsx')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/announcement',
            name: 'announcement',
            title: '公告管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Announcement" */ '../Administrative/Announcement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Announcement').default,
            exact: true,
          },
          {
            path: '/administrative/structure',
            name: 'structure',
            title: '组织架构',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__OrganStructer" */ '../Administrative/OrganStructer'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/OrganStructer').default,
            exact: true,
          },
          {
            path: '/administrative/history/:date',
            name: 'history',
            title: '历史归档',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__OrganHistory" */ '../Administrative/OrganHistory'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/OrganHistory').default,
            exact: true,
          },
          {
            path: '/administrative/duty',
            name: 'duty',
            title: '职务级别',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__DutyLevel__DutyLevel" */ '../Administrative/DutyLevel/DutyLevel.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/DutyLevel/DutyLevel.jsx').default,
            exact: true,
          },
          {
            path: '/administrative/all',
            name: 'all',
            title: '架构一览',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__OrganizationalStructure" */ '../Administrative/OrganizationalStructure'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/OrganizationalStructure').default,
            exact: true,
          },
          {
            path: '/administrative/approval',
            name: 'approval',
            title: '审批配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Approval__ApprovalList" */ '../Administrative/Approval/ApprovalList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Approval/ApprovalList').default,
            exact: true,
          },
          {
            path: '/administrative/configure',
            name: 'configure',
            hideInMenu: true,
            title: '审批配置详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Approval__ApprovalConfig" */ '../Administrative/Approval/ApprovalConfig'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Approval/ApprovalConfig').default,
            exact: true,
          },
          {
            path: '/administrative/statistics',
            title: '审批统计',
            name: 'statistics',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Approval__ApprovalStatistics" */ '../Administrative/Approval/ApprovalStatistics'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Approval/ApprovalStatistics')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/regulations',
            name: 'regulations',
            title: '规章制度',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Regulations__RegulationList" */ '../Administrative/Regulations/RegulationList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Regulations/RegulationList').default,
            exact: true,
          },
          {
            path: '/administrative/newregulation',
            name: 'newregulation',
            title: '新建规章制度',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Regulations__RegulationModify" */ '../Administrative/Regulations/RegulationModify'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Regulations/RegulationModify')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/modifyregulation/:id',
            name: 'modifyregulation',
            title: '编辑规章制度',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Regulations__RegulationModify" */ '../Administrative/Regulations/RegulationModify'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Regulations/RegulationModify')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/index',
            name: 'index',
            title: '审批设置',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalIndex" */ '../Administrative/ApprovalConfig/ApprovalIndex'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalIndex')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/settingDetail',
            name: 'settingDetail',
            hideInMenu: true,
            title: '审批配置详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalConfig" */ '../Administrative/ApprovalConfig/ApprovalConfig'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalConfig')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/group',
            name: 'group',
            hideInMenu: true,
            title: '审批分组',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalGroup" */ '../Administrative/ApprovalConfig/ApprovalGroup'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalGroup')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/approvalPDF',
            name: 'approvalPDF',
            hideInMenu: true,
            title: '审批PDF下载',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalPDF" */ '../Administrative/ApprovalConfig/ApprovalPDF'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalPDF').default,
            exact: true,
          },
          {
            path: '/administrative/criteria',
            name: 'criteria',
            hideInMenu: true,
            title: '添加条件审批流程',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalCriteria" */ '../Administrative/ApprovalConfig/ApprovalCriteria'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalCriteria')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/examination',
            name: 'examination',
            title: '审批监控',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Approval__ApprovalExamination" */ '../Administrative/Approval/ApprovalExamination'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Approval/ApprovalExamination')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/record',
            name: 'record',
            title: '审批记录',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__ApprovalConfig__ApprovalRecord" */ '../Administrative/ApprovalConfig/ApprovalRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/ApprovalConfig/ApprovalRecord')
                  .default,
            exact: true,
          },
          {
            path: '/administrative/file',
            name: 'file',
            hideInMenu: true,
            title: '文档管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Document__DocumentList" */ '../Document/DocumentList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Document/DocumentList').default,
            exact: true,
          },
        ],
      },
      {
        path: '/asset',
        title: 'IT设备管理',
        name: 'asset',
        icon: 'laptop',
        routes: [
          {
            path: '/asset',
            title: '重定向',
            redirect: '/asset/device',
            exact: true,
          },
          {
            path: '/asset/device',
            name: 'device',
            title: '设备列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Equipment__EquipmentManage" */ '../Equipment/EquipmentManage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Equipment/EquipmentManage').default,
            exact: true,
          },
          {
            path: '/asset/distribution',
            name: 'distribution',
            title: '设备分布',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__EquipmentDistribution__EquipmentDistribution" */ '../EquipmentDistribution/EquipmentDistribution'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../EquipmentDistribution/EquipmentDistribution')
                  .default,
            exact: true,
          },
          {
            path: '/asset/audit',
            name: 'audit',
            title: '邮箱分配',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Administrative__Mailbox" */ '../Administrative/Mailbox'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Administrative/Mailbox').default,
            exact: true,
          },
        ],
      },
      {
        path: '/business',
        icon: 'container',
        title: '业务',
        name: 'business',
        routes: [
          {
            path: '/business/addOpportunity',
            name: 'addOpportunity',
            title: '新增商机',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__AddOpportunity" */ '../Business/AddOpportunity'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/AddOpportunity').default,
            exact: true,
          },
          {
            path: '/business/custom/opportunity',
            name: 'opportunity',
            title: '商机列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Opportunity" */ '../Business/Opportunity'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Opportunity').default,
            exact: true,
          },
          {
            path: '/business/editOpportunity/:id',
            name: 'editOpportunity',
            title: '编辑商机',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__AddOpportunity" */ '../Business/AddOpportunity'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/AddOpportunity').default,
            exact: true,
          },
          {
            path: '/business/oppDetail/:id',
            name: 'oppDetail',
            title: '商机详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__OpportunityDetail" */ '../Business/OpportunityDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/OpportunityDetail').default,
            exact: true,
          },
          {
            path: '/business/addCustomer',
            name: 'addCustomer',
            title: '新增客户',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__CustomerForm" */ '../Business/CustomerForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/CustomerForm').default,
            exact: true,
          },
          {
            path: '/business/customer',
            name: 'customer',
            title: '客户列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__CustomerList" */ '../Business/CustomerList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/CustomerList').default,
            exact: true,
          },
          {
            path: '/business/customerDetail/:id',
            name: 'customerDetail',
            title: '客户详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__CustomerDetail" */ '../Business/CustomerDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/CustomerDetail').default,
            exact: true,
          },
          {
            path: '/business/editCustomer/:id',
            name: 'editCustomer',
            title: '编辑客户',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__CustomerForm" */ '../Business/CustomerForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/CustomerForm').default,
            exact: true,
          },
          {
            path: '/business/addContact',
            name: 'addContact',
            hideInMenu: true,
            title: '添加客户联系人',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__ContactForm" */ '../Business/ContactForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/ContactForm').default,
            exact: true,
          },
          {
            path: '/business/editContact/:id',
            name: 'editContact',
            hideInMenu: true,
            title: '编辑客户联系人',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__ContactForm" */ '../Business/ContactForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/ContactForm').default,
            exact: true,
          },
          {
            path: '/business/contactDeatil/:id',
            name: 'contactDeatil',
            hideInMenu: true,
            title: '客户联系人详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__ContactDetail" */ '../Business/ContactDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/ContactDetail').default,
            exact: true,
          },
          {
            path: '/business/addContract',
            name: 'addContract',
            title: '添加合同',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractEvaluationForm" */ '../Business/Contract/ContractEvaluationForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractEvaluationForm').default,
            exact: true,
          },
          {
            path: '/business/contractList',
            name: 'contractList',
            title: '合同列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractList" */ '../Business/Contract/ContractList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractList').default,
            exact: true,
          },
          {
            path: '/business/editContract/:id',
            name: 'editContract',
            title: '编辑合同',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractEvaluationForm" */ '../Business/Contract/ContractEvaluationForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractEvaluationForm').default,
            exact: true,
          },
          {
            path: '/business/contractDetail/:id',
            name: 'contractDetail',
            hideInMenu: true,
            title: '合同详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractDetail" */ '../Business/Contract/ContractDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractDetail').default,
            exact: true,
          },
          {
            path: '/business/contractReview/:id',
            name: 'contractReview',
            hideInMenu: true,
            title: '合同评审',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractProcessDetail" */ '../Business/Contract/ContractProcessDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractProcessDetail').default,
            exact: true,
          },
          {
            path: '/business/project',
            name: 'project',
            title: '项目管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectManager" */ '../Project/ProjectManager'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectManager').default,
            exact: true,
          },
        ],
      },
      {
        path: '/commercial',
        title: '商务',
        name: 'commercial',
        icon: 'solution',
        routes: [
          {
            path: '/commercial/grossprofit',
            title: '毛利表',
            name: 'grossprofit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Grossprofit__GrossProfit" */ '../Business/Grossprofit/GrossProfit'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Grossprofit/GrossProfit').default,
            exact: true,
          },
          {
            path: '/commercial/staffWorkHour',
            name: 'staffWorkHour',
            title: '工时导入',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__StaffWorkHours" */ '../Attendance/HoursCheck/StaffWorkHours'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/StaffWorkHours').default,
            exact: true,
          },
          {
            path: '/commercial/staffWorkHour/operationLogDetails',
            name: 'operationLogDetails',
            title: '工时核对操作记录',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__OperationLogDetails" */ '../Attendance/HoursCheck/OperationLogDetails'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/OperationLogDetails').default,
            exact: true,
          },
          {
            path: '/commercial/staffWorkHour/checkDetail/:id',
            name: 'checkDetail',
            title: '工时详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__CheckDetail" */ '../Attendance/HoursCheck/CheckDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/CheckDetail').default,
            exact: true,
          },
          {
            path: '/commercial/pay',
            title: '付费表确定',
            name: 'pay',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__PayList" */ '../Attendance/HoursCheck/PayList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/PayList').default,
            exact: true,
          },
          {
            path: '/commercial/payMonth',
            title: '付费月统计表',
            name: 'payMonth',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__PayMonthList" */ '../Attendance/HoursCheck/PayMonthList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/PayMonthList').default,
            exact: true,
          },
          {
            path: '/commercial/contract',
            name: 'contract',
            title: '合同列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractList" */ '../Business/Contract/ContractList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractList').default,
            exact: true,
          },
          {
            path: '/commercial/leaveList',
            title: '离项工时',
            name: 'leaveList',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Attendance__HoursCheck__LeaveStaffHours" */ '../Attendance/HoursCheck/LeaveStaffHours'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Attendance/HoursCheck/LeaveStaffHours').default,
            exact: true,
          },
        ],
      },
      {
        path: '/project',
        title: '项目',
        icon: 'project',
        name: 'project',
        routes: [
          {
            path: '/project/manage',
            name: 'manage',
            title: '项目管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectManager" */ '../Project/ProjectManager'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectManager').default,
            exact: true,
          },
          {
            path: '/project/projectPersonnel',
            name: 'projectPersonnel',
            title: '项目人员',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__projectPersonnel__projectPersonnel" */ '../Project/projectPersonnel/projectPersonnel'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/projectPersonnel/projectPersonnel').default,
            exact: true,
          },
          {
            title: '项目考勤管理',
            path: '/project/project-attendance-management',
            name: 'projectAttendanceManagement',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectAttendanceManagement__ProjectAttendanceManagement" */ '../Project/ProjectAttendanceManagement/ProjectAttendanceManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectAttendanceManagement/ProjectAttendanceManagement')
                  .default,
            exact: true,
          },
          {
            title: '（交付）确认申报详情',
            path: '/project/declare-the-details/:id',
            name: 'projectDeclareTheDetails',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectAttendanceManagement__projectDeclareTheDetails" */ '../Project/ProjectAttendanceManagement/projectDeclareTheDetails'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectAttendanceManagement/projectDeclareTheDetails')
                  .default,
            exact: true,
          },
          {
            title: '工时核对详情',
            path: '/project/TimeCheckDetails/:id',
            name: 'TimeCheckDetails',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectAttendanceManagement__TimeCheckDetails" */ '../Project/ProjectAttendanceManagement/TimeCheckDetails'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectAttendanceManagement/TimeCheckDetails')
                  .default,
            exact: true,
          },
          {
            title: '运营资源',
            path: '/project/operating-resources',
            name: 'operatingResources',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Interactive__OperatingResources__OperatingResources" */ '../Interactive/OperatingResources/OperatingResources'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Interactive/OperatingResources/OperatingResources')
                  .default,
            exact: true,
          },
          {
            title: '交付计划表',
            path: '/project/plan-table',
            name: 'planTable',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Interactive__PlanTable__PlanTable" */ '../Interactive/PlanTable/PlanTable'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Interactive/PlanTable/PlanTable').default,
            exact: true,
          },
          {
            path: '/project/detail/:id',
            name: 'detail',
            title: '项目详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectDetail" */ '../Project/ProjectDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectDetail').default,
            exact: true,
          },
          {
            path: '/project/changeRecord/:id',
            name: 'changeRecord',
            title: '异动记录',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ChangeRecord" */ '../Project/ChangeRecord'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ChangeRecord').default,
            exact: true,
          },
          {
            path: '/project/hisdeclaration',
            title: '考勤申报详情',
            name: 'hisdeclaration',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__WorkBench__Attendance__HisDeclaration" */ '../WorkBench/Attendance/HisDeclaration'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../WorkBench/Attendance/HisDeclaration').default,
            exact: true,
          },
          {
            path: '/project/turnover/:id',
            name: 'turnover',
            title: '离项员工详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__LeaveProjectDetail" */ '../Project/LeaveProjectDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/LeaveProjectDetail').default,
            exact: true,
          },
          {
            path: '/project/releaseForm',
            name: 'releaseForm',
            title: '释放沟通情况表',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ReleaseCommunication" */ '../Project/ReleaseCommunication'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ReleaseCommunication').default,
            exact: true,
          },
          {
            path: '/project/transfer/:id',
            name: 'transfer',
            title: '异动管理',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectTransferList" */ '../Project/ProjectTransferList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectTransferList').default,
            exact: true,
          },
          {
            path: '/project/transaction/:id',
            name: 'transaction',
            hideInMenu: true,
            title: '异动审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Transaction" */ '../Project/Transaction'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Transaction').default,
            exact: true,
          },
          {
            path: '/project/delayWage',
            name: 'delayWage',
            hideInMenu: true,
            title: '工资缓发审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateDelayWage" */ '../Approval/InitiateForm/InitiateDelayWage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateDelayWage').default,
            exact: true,
          },
          {
            path: '/project/demand',
            name: 'demand',
            title: '需求表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Demand__DemandList" */ '../Project/Demand/DemandList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Demand/DemandList').default,
            exact: true,
          },
          {
            path: '/project/demand/addDemand',
            name: 'addDemand',
            title: '新建线下需求',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Demand__AddOfflineDemand" */ '../Project/Demand/AddOfflineDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Demand/AddOfflineDemand').default,
            exact: true,
          },
          {
            path: '/project/candidateFollowUp',
            name: 'candidateFollowUp',
            title: '候选人跟进',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Demand__CandidateFollowUp" */ '../Project/Demand/CandidateFollowUp.jsx'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Demand/CandidateFollowUp.jsx').default,
            exact: true,
          },
          {
            path: '/project/allot',
            name: 'allot',
            title: '待分配需求',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Docking__SystemUnallocateDemand" */ '../Project/Docking/SystemUnallocateDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Docking/SystemUnallocateDemand').default,
            exact: true,
          },
          {
            path: '/project/allotNew',
            name: 'allotNew',
            title: '待分配需求New',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__NeedsPending__NeedsPending" */ '../Project/NeedsPending/NeedsPending'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/NeedsPending/NeedsPending').default,
            exact: true,
          },
          {
            path: '/project/release',
            name: 'release',
            title: '释放管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ReleaseManagement" */ '../Personnel/ReleaseManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ReleaseManagement').default,
            exact: true,
          },
          {
            path: '/project/resourcePool',
            name: 'resourcePool',
            title: '资源池',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ResourcePool" */ '../Personnel/ResourcePool'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ResourcePool').default,
            exact: true,
          },
          {
            path: '/project/resumeDetail/:id',
            name: 'resumeDetail',
            title: '简历详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__RecruitmentDetail" */ '../Recruitment/RecruitmentDetail.js'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/RecruitmentDetail.js').default,
            exact: true,
          },
          {
            path: '/project/requirements',
            name: 'requirements',
            title: '系统需求导入',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Docking__SystemOriginDemand" */ '../Project/Docking/SystemOriginDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Docking/SystemOriginDemand').default,
            exact: true,
          },
          {
            path: '/project/setting',
            name: 'setting',
            title: '跟进设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Docking__SystemFollowSetting" */ '../Project/Docking/SystemFollowSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Docking/SystemFollowSetting').default,
            exact: true,
          },
        ],
      },
      {
        path: '/recruit',
        title: '招聘',
        icon: 'file-search',
        name: 'recruit',
        routes: [
          {
            path: '/recruit/addResume',
            name: 'add',
            title: '新增简历',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__RecruitmentForm" */ '../Recruitment/RecruitmentForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/RecruitmentForm').default,
            exact: true,
          },
          {
            path: '/recruit/Resumepush',
            name: 'Resumepush',
            title: '简历推送',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__ResumepushForm" */ '../Recruitment/ResumepushForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/ResumepushForm').default,
            exact: true,
          },
          {
            path: '/recruit/lockResume',
            title: '锁定简历',
            name: 'lockResume',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__LockResume" */ '../Recruitment/LockResume'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/LockResume').default,
            exact: true,
          },
          {
            path: '/recruit/interviewResult',
            title: '面试结果',
            name: 'interviewResult',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__InterviewResult" */ '../Recruitment/InterviewResult'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/InterviewResult').default,
            exact: true,
          },
          {
            path: '/recruit/list',
            title: '人才简历库',
            name: 'list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__ResumeLib" */ '../Recruitment/ResumeLib'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/ResumeLib').default,
            exact: true,
          },
          {
            path: '/recruit/edit/:id',
            name: 'edit',
            title: '编辑简历',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__RecruitmentForm" */ '../Recruitment/RecruitmentForm'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/RecruitmentForm').default,
            exact: true,
          },
          {
            path: '/recruit/detail/:id',
            name: 'detail',
            title: '简历详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__RecruitmentDetail" */ '../Recruitment/RecruitmentDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/RecruitmentDetail').default,
            exact: true,
          },
          {
            path: '/recruit/releasedb',
            name: 'releasedb',
            title: '释放人才库',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__ReleaseResumeLib" */ '../Recruitment/ReleaseResumeLib'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/ReleaseResumeLib').default,
            exact: true,
          },
          {
            path: '/recruit/log',
            name: 'log',
            title: '操作记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__ResumeOperateLog" */ '../Recruitment/ResumeOperateLog'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/ResumeOperateLog').default,
            exact: true,
          },
          {
            path: '/recruit/project',
            name: 'project',
            title: '项目管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__ProjectManager" */ '../Project/ProjectManager'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/ProjectManager').default,
            exact: true,
          },
          {
            path: '/recruit/schedule',
            name: 'schedule',
            title: '招聘需求池',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__Schedule" */ '../Recruitment/Schedule'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/Schedule').default,
            exact: true,
          },
          {
            path: '/recruit/departmentCandidatesFollowUp',
            name: 'departmentCandidates',
            title: '本部候选人跟进',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__DepartmentCandidatesFollowUp" */ '../Recruitment/DepartmentCandidatesFollowUp.js'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/DepartmentCandidatesFollowUp.js')
                  .default,
            exact: true,
          },
          {
            path: '/recruit/release',
            name: 'release',
            title: '释放管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Personnel__ReleaseManagement" */ '../Personnel/ReleaseManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Personnel/ReleaseManagement').default,
            exact: true,
          },
          {
            path: '/recruit/headquarterDemand',
            name: 'headquarterDemand',
            title: '本部需求表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Recruitment__HeadquarterDemand__HeadquarterDemand" */ '../Recruitment/HeadquarterDemand/HeadquarterDemand'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Recruitment/HeadquarterDemand/HeadquarterDemand')
                  .default,
            exact: true,
          },
        ],
      },
      {
        path: '/finance',
        icon: 'bank',
        title: '财务',
        name: 'finance',
        routes: [
          {
            title: '税前工资',
            path: '/finance/pre-tax',
            name: 'pre',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__PreTaxWages" */ '../Finance/WageCalculation/PreTaxWages'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/PreTaxWages').default,
            exact: true,
          },
          {
            title: '个税表',
            path: '/finance/individual',
            name: 'individual',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__IndividualTax" */ '../Finance/WageCalculation/IndividualTax'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/IndividualTax').default,
            exact: true,
          },
          {
            title: '税后工资',
            path: '/finance/after-tax',
            name: 'after',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__AfterTaxWages" */ '../Finance/WageCalculation/AfterTaxWages'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/AfterTaxWages').default,
            exact: true,
          },
          {
            title: '员工工资单',
            path: '/finance/person/:id',
            name: 'person',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__PersonWages" */ '../Finance/WageCalculation/PersonWages'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/PersonWages').default,
            exact: true,
          },
          {
            title: '历史工资表',
            path: '/finance/historySalary',
            name: 'historySalary',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__HistoryWages" */ '../Finance/WageCalculation/HistoryWages'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/HistoryWages').default,
            exact: true,
          },
          {
            path: '/finance/transfer',
            name: 'transfer',
            title: '员工异动表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__StaffTransferList" */ '../Finance/StaffTransferList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/StaffTransferList').default,
            exact: true,
          },
          {
            path: '/finance/historyDetail',
            title: '历史工资详情',
            name: 'historyDetail',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__WageCalculation__AfterTaxWages" */ '../Finance/WageCalculation/AfterTaxWages'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/WageCalculation/AfterTaxWages').default,
            exact: true,
          },
          {
            path: '/finance/invoice',
            name: 'invoice',
            title: '开票申请',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__InvoiceList" */ '../Finance/InvoiceList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/InvoiceList').default,
            exact: true,
          },
          {
            path: '/finance/print',
            name: 'print',
            title: '开票信息打印',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__InvoivePrintPreview" */ '../Finance/InvoivePrintPreview'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/InvoivePrintPreview').default,
            exact: true,
          },
          {
            path: '/finance/back',
            name: 'back',
            title: '客户回款',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__BackList" */ '../Finance/BackList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/BackList').default,
            exact: true,
          },
          {
            path: '/finance/contract',
            name: 'contract',
            title: '客户合同',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Business__Contract__ContractList" */ '../Business/Contract/ContractList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Business/Contract/ContractList').default,
            exact: true,
          },
          {
            path: '/finance/operate',
            name: 'operate',
            title: '操作记录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Finance__FinanceOperateLog" */ '../Finance/FinanceOperateLog'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Finance/FinanceOperateLog').default,
            exact: true,
          },
        ],
      },
      {
        name: 'system',
        icon: 'notification',
        path: '/system',
        title: '系统通知',
        hideInMenu: true,
        routes: [
          {
            path: '/system/news',
            name: 'news',
            title: '消息',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__News" */ '../Notification/News'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/News').default,
            exact: true,
          },
          {
            path: '/system/releasenews',
            name: 'releasenews',
            title: '释放通知单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__ReleaseNotification" */ '../Notification/ReleaseNotification'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/ReleaseNotification').default,
            exact: true,
          },
          {
            path: '/system/refuseRepeat',
            name: 'refuseRepeat',
            title: '拒绝复推告知函',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__RefuseRepeat" */ '../Notification/RefuseRepeat'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/RefuseRepeat').default,
            exact: true,
          },
          {
            path: '/system/announcement',
            name: 'announcement',
            title: '系统公告',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__SystemNotices" */ '../Notification/SystemNotices'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/SystemNotices').default,
            exact: true,
          },
          {
            path: '/system/todo',
            name: 'todo',
            title: '待办任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__TodoList" */ '../Notification/TodoList'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/TodoList').default,
            exact: true,
          },
          {
            path: '/system/approval',
            name: 'approval',
            title: '我的审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__MyApproval" */ '../Notification/MyApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/MyApproval').default,
            exact: true,
          },
          {
            path: '/system/todo/turnover',
            name: 'turnover',
            hideInMenu: true,
            title: '转正申请',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__TurnoverTask" */ '../Notification/Tasks/TurnoverTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/TurnoverTask').default,
            exact: true,
          },
          {
            path: '/system/todo/feedback',
            name: 'feedback',
            hideInMenu: true,
            title: '确认付费时间',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__ArrivalInterviewFeedbackTask" */ '../Notification/Tasks/ArrivalInterviewFeedbackTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/ArrivalInterviewFeedbackTask')
                  .default,
            exact: true,
          },
          {
            path: '/system/todo/demandClose',
            name: 'demandClose',
            hideInMenu: true,
            title: '线上需求关闭',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__DemandCloseRemark" */ '../Notification/Tasks/DemandCloseRemark'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/DemandCloseRemark').default,
            exact: true,
          },
          {
            path: '/system/todo/renewalBack',
            name: 'renewalBack',
            hideInMenu: true,
            title: '续签反馈',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__RenewalTask" */ '../Notification/Tasks/RenewalTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/RenewalTask').default,
            exact: true,
          },
          {
            path: '/system/todo/renewal',
            name: 'renewalTask',
            title: '续签通知书',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__RenewalStaffTask" */ '../Notification/Tasks/RenewalStaffTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/RenewalStaffTask').default,
            exact: true,
          },
          {
            path: '/system/todo/leaveSurvey',
            name: 'leaveSurvey',
            hideInMenu: true,
            title: '离职调查表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__StaffLeaveSurvey" */ '../Notification/Tasks/StaffLeaveSurvey'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/StaffLeaveSurvey').default,
            exact: true,
          },
          {
            path: '/system/todo/leaveSure',
            name: 'leaveSure',
            hideInMenu: true,
            title: '离职确认表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__StaffLeaveSure" */ '../Notification/Tasks/StaffLeaveSure'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/StaffLeaveSure').default,
            exact: true,
          },
          {
            path: '/system/todo/leaderSureForStaff',
            name: 'leaderSureForStaff',
            title: '上级离职确认表',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__LeaderSureForStaff" */ '../Notification/Tasks/LeaderSureForStaff'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/LeaderSureForStaff').default,
            exact: true,
          },
          {
            path: '/system/todo/staffLeaveSigned',
            name: 'staffLeaveSigned',
            hideInMenu: true,
            title: '离职会签单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__StaffLeaveSigned" */ '../Notification/Tasks/StaffLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/StaffLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/attendanceLeaveSigned',
            name: 'attendanceLeaveSigned',
            hideInMenu: true,
            title: '考勤专员-离职会签单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__AttendanceLeaveSigned" */ '../Notification/Tasks/AttendanceLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/AttendanceLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/socialLeaveSigned',
            name: 'socialLeaveSigned',
            hideInMenu: true,
            title: '社保专员-离职会签单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__SocialLeaveSigned" */ '../Notification/Tasks/SocialLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/SocialLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/equLeaveSigned',
            name: 'equLeaveSigned',
            title: '设备管理员-离职会签单',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__EquLeaveSigned" */ '../Notification/Tasks/EquLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/EquLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/financialLeaveSigned',
            name: 'financialLeaveSigned',
            hideInMenu: true,
            title: '财务-离职会签单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__FinancialLeaveSigned" */ '../Notification/Tasks/FinancialLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/FinancialLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/seniorLeaveSigned',
            name: 'seniorLeaveSigned',
            hideInMenu: true,
            title: '离职会签单审签',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__SeniorLeaveSigned" */ '../Notification/Tasks/SeniorLeaveSigned'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/SeniorLeaveSigned').default,
            exact: true,
          },
          {
            path: '/system/todo/seniorLeaveAuthorized',
            name: 'seniorLeaveAuthorized',
            hideInMenu: true,
            title: '离职会签单签批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__SeniorLeaveAuthorized" */ '../Notification/Tasks/SeniorLeaveAuthorized'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/SeniorLeaveAuthorized').default,
            exact: true,
          },
          {
            path: '/system/todo/entrytask',
            name: 'entrytask',
            title: '候选人跟进任务',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__EntryTask" */ '../Notification/Tasks/EntryTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/EntryTask').default,
            exact: true,
          },
          {
            path: '/system/todo/join',
            name: 'join',
            title: '入项确认',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__JoinProjectSure" */ '../Notification/Tasks/JoinProjectSure'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/JoinProjectSure').default,
            exact: true,
          },
          {
            path: '/system/todo/holidaytask',
            name: 'holidaytask',
            title: '请假证明补充任务',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__HolidayTask" */ '../Notification/Tasks/HolidayTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/HolidayTask').default,
            exact: true,
          },
          {
            path: '/system/todo/abnormalTask',
            name: 'abnormalTask',
            hideInMenu: true,
            title: '初核异常任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__InitialAbnormalTask" */ '../Notification/Tasks/InitialAbnormalTask'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/InitialAbnormalTask').default,
            exact: true,
          },
          {
            path: '/system/todo/returnDevice',
            name: 'returnDevice',
            title: '归还设备任务',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__ReturnDevice" */ '../Notification/Tasks/ReturnDevice'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/ReturnDevice').default,
            exact: true,
          },
          {
            path: '/system/todo/replynotice',
            name: 'replynotice',
            title: '复推告知函',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__ReplyNotice" */ '../Notification/Tasks/ReplyNotice'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/ReplyNotice').default,
            exact: true,
          },
          {
            path: '/system/todo/releaseGuidelines',
            name: 'releaseGuidelines',
            title: '释放指引',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__ReleaseGuidelines" */ '../Notification/Tasks/ReleaseGuidelines'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/ReleaseGuidelines').default,
            exact: true,
          },
          {
            path: '/system/todo/departure',
            name: 'departure',
            title: '离项任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__Departure" */ '../Notification/Tasks/Departure'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/Departure').default,
            exact: true,
          },
          {
            path: '/system/todo/confirmDeparture',
            name: 'confirmDeparture',
            title: '离项任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__ConfirmDeparture" */ '../Notification/Tasks/ConfirmDeparture'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/ConfirmDeparture').default,
            exact: true,
          },
          {
            path: '/system/todo/hourConfirmation',
            name: 'hourConfirmation',
            title: '离项工时确认',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Notification__Tasks__HourConfirmation" */ '../Notification/Tasks/HourConfirmation'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Notification/Tasks/HourConfirmation').default,
            exact: true,
          },
          {
            path: '/system/approval/turnover/:id',
            name: 'turnoverDetail',
            hideInMenu: true,
            title: '转正申请详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__TurnoverApprovalDetail" */ '../Approval/ApprovalDetails/TurnoverApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/TurnoverApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/todo/missionDetails/:id',
            name: 'missionDetails',
            hideInMenu: true,
            title: '转正任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__MissionDetails" */ '../Approval/ApprovalDetails/MissionDetails'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/MissionDetails').default,
            exact: true,
          },
          {
            path: '/system/approval/renewal/:id',
            name: 'renewal',
            title: '续签审批',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetail__RenewalApprovalDetail" */ '../Approval/ApprovalDetail/RenewalApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetail/RenewalApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/proof/:id',
            name: 'proofDetail',
            hideInMenu: true,
            title: '证明开具申请详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__ProofApprovalDetail" */ '../Approval/ApprovalDetails/ProofApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/ProofApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/reProof/:procDetailId',
            name: 'reProof',
            title: '重启证明开具',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateProofApproval" */ '../Approval/InitiateForm/InitiateProofApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateProofApproval')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/delayWage/:id',
            name: 'delayWage',
            hideInMenu: true,
            title: '工资缓发审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__DelayWageApprovalDetail" */ '../Approval/ApprovalDetails/DelayWageApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/DelayWageApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/reDelayWage/:procDetailId',
            name: 'reDelayWage',
            title: '重启工资缓发',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateDelayWage" */ '../Approval/InitiateForm/InitiateDelayWage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateDelayWage').default,
            exact: true,
          },
          {
            path: '/system/approval/transaction/:id',
            name: 'transaction',
            hideInMenu: true,
            title: '异动审批详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__DelayTransactionDetail" */ '../Approval/ApprovalDetails/DelayTransactionDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/DelayTransactionDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/retransaction/:procDetailId',
            name: 'retransaction',
            hideInMenu: true,
            title: '重启异动审批',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Project__Transaction" */ '../Project/Transaction'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Project/Transaction').default,
            exact: true,
          },
          {
            path: '/system/approval/reimbursement/:id',
            name: 'reimbursementDetail',
            hideInMenu: true,
            title: '报销流程详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__ApprovalDetails__ReimbursementApprovalDetail" */ '../Approval/ApprovalDetails/ReimbursementApprovalDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/ApprovalDetails/ReimbursementApprovalDetail')
                  .default,
            exact: true,
          },
          {
            path: '/system/approval/reReimbursement/:procDetailId',
            name: 'reReimbursement',
            title: '重启报销流程',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Approval__InitiateForm__InitiateReimbursementApproval" */ '../Approval/InitiateForm/InitiateReimbursementApproval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Approval/InitiateForm/InitiateReimbursementApproval')
                  .default,
            exact: true,
          },
        ],
      },
      {
        name: 'exception',
        title: '错误码',
        icon: 'exception',
        path: '/exception',
        routes: [
          {
            path: '/exception/404',
            name: '404',
            title: '请求的网页不存在',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/data/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/403',
            name: '403',
            title: '未授权',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/data/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: '500',
            title: '服务器内部错误',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/data/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
        ],
      },
      {
        name: 'setting',
        title: '系统设置',
        icon: 'setting',
        path: '/setting',
        routes: [
          {
            path: '/setting/role',
            name: 'role',
            title: '职务权限',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__Roles" */ '../SystemSetting/Roles'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/Roles').default,
            exact: true,
          },
          {
            path: '/setting/roleButton/:id',
            name: 'role',
            title: '职务按钮权限',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__RoleButton" */ '../SystemSetting/RoleButton'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/RoleButton').default,
            exact: true,
          },
          {
            path: '/setting/menuManage',
            name: 'menuManage',
            title: '菜单管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__MenuManage" */ '../SystemSetting/MenuManage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/MenuManage').default,
            exact: true,
          },
          {
            path: '/setting/role/:id',
            name: 'roleSetting',
            title: '职务详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__RoleSetting" */ '../SystemSetting/RoleSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/RoleSetting').default,
            exact: true,
          },
          {
            path: '/setting/user',
            name: 'user',
            title: '用户管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__UserManagement" */ '../SystemSetting/UserManagement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/UserManagement').default,
            exact: true,
          },
          {
            path: '/setting/user/:id',
            name: 'userDetail',
            title: '用户详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__UserDetail" */ '../SystemSetting/UserDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/UserDetail').default,
            exact: true,
          },
          {
            path: '/setting/loginLog',
            name: 'loginLog',
            title: '登录日志',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__LoginLog" */ '../SystemSetting/LoginLog'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/LoginLog').default,
            exact: true,
          },
          {
            path: '/setting/message',
            name: 'message',
            title: '消息设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__MessageSetting" */ '../SystemSetting/MessageSetting'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/MessageSetting').default,
            exact: true,
          },
          {
            path: '/setting/textMessage',
            name: 'textMessage',
            title: '标签设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__TextMessage" */ '../SystemSetting/TextMessage'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/TextMessage').default,
            exact: true,
          },
          {
            path: '/setting/template',
            name: 'template',
            title: '模板管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__Template" */ '../SystemSetting/Template'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/Template').default,
            exact: true,
          },
          {
            path: '/setting/systemFeedBack',
            name: 'systemFeedBack',
            title: '用户反馈',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__SystemFeedBack" */ '../SystemSetting/SystemFeedBack'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/SystemFeedBack').default,
            exact: true,
          },
          {
            path: '/setting/feedBack/:id',
            name: 'feedBackDetail',
            title: '用户反馈详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__FeedBackDetail" */ '../SystemSetting/FeedBackDetail'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/FeedBackDetail').default,
            exact: true,
          },
          {
            path: '/setting/updateDynamics',
            name: 'updateDynamics',
            title: '更新动态',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__UpdateDynamics" */ '../SystemSetting/UpdateDynamics'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/UpdateDynamics').default,
            exact: true,
          },
          {
            path: '/setting/serviceAgreement',
            name: 'serviceAgreement',
            title: '服务协议',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__ServiceAgreement" */ '../SystemSetting/ServiceAgreement'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/ServiceAgreement').default,
            exact: true,
          },
          {
            path: '/setting/dataDictionary',
            name: 'dataDictionary',
            title: '数据字典',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__DataDictionary" */ '../SystemSetting/DataDictionary'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/DataDictionary').default,
            exact: true,
          },
          {
            path: '/setting/dataKeyValue',
            name: 'dataKeyValue',
            title: '数据键值',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__SystemSetting__DataKeyValue" */ '../SystemSetting/DataKeyValue'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../SystemSetting/DataKeyValue').default,
            exact: true,
          },
          {
            path: '/setting/operation',
            title: '操作记录',
            name: 'operation',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Journal__operation" */ '../Journal/operation'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Journal/operation').default,
            exact: true,
          },
          {
            path: '/setting/approval',
            title: '审批日志',
            name: 'approval',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Journal__approval" */ '../Journal/approval'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Journal/approval').default,
            exact: true,
          },
          {
            path: '/setting/system',
            title: '系统日志',
            name: 'system',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Journal__system" */ '../Journal/system'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Journal/system').default,
            exact: true,
          },
          {
            path: '/setting/export',
            title: '导出日志',
            name: 'export',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Journal__export" */ '../Journal/export'),
                  LoadingComponent: require('/data/src/components/PageLoading/index')
                    .default,
                })
              : require('../Journal/export').default,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
